.team-inbox-section{
    display: flex;
    width: 100% !important;
    height: 90vh !important;
}

#scroll-contacts{
position: sticky;
top: 0;
height: 90vh;

}
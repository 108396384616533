.inbox-contacts-section{
    min-width: 22%;
    max-width: 22%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 20px 10px;
}

.search-table-input {
    display: flex;
    align-items: center;
}

.s-table-input {
    padding: 5px 10px;
    border-radius: 6px;
    border: 1px solid #c0c0c081;
    font-size: 14px !important;
    background-color: #F2F2F2;
    width: 100% !important;
    display: flex;
    justify-content: center;
}

.s-table-input:focus {
    outline: none;
}

#search-icon {
    font-size: 20px !important;
    color: rgb(150, 150, 150);
    font-weight: bolder;
    padding: 0px 5px;
    position: absolute;
    top: 14%;
    pointer-events: none;
    margin-right: 30px;
}

.message-list {
    padding: 10px 10px;
    margin: 4px 0px;
    border-bottom: solid 1px rgb(184, 184, 184);
    cursor: pointer;
  }

  .message-list:hover {
   background-color: aliceblue;
  }
  
.contact-name{
    font-weight: bold;
    font-size: 15px;
}

.contact-message{
    font-size: 14px;
    font-weight: 600;
    max-height: 6em; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #575757; 
}

.contact-group-name{
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: 16px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: rgba(227, 227, 227, 0.483);
  color: rgb(92, 92, 92);
  margin-top: 5px;
  text-transform: capitalize !important;
  width: max-content;
}

.contact-chat-status-info{
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.contact-message-status-OPEN {
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: rgb(226, 245, 212);
  color: rgb(28, 184, 69);
  display: flex;
  justify-content: left;
  margin-top: 5px;
  text-transform: capitalize !important;
}
.contact-message-status-PENDING {
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  padding: 2px 5px;
  border-radius: 5px;
  text-transform: capitalize !important;
  background-color: rgb(245, 244, 212);
  color: rgb(243, 206, 23);
  display: flex;
  justify-content: left;
  margin-top: 5px;
}
.contact-message-status-SOLVED{
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: rgb(207, 240, 253);
  color: rgb(25, 146, 194);
  display: flex;
  justify-content: left;
  margin-top: 5px;
}

.contact-message-status-EXPIRED{
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: rgb(253, 207, 207);
  color: rgb(202, 16, 16);
  display: flex;
  justify-content: left;
  margin-top: 5px;
}


  
.contact-message-datetime {
    font-size: 12px;
    font-weight: bold;
    color: #575757;
    display: flex;
    justify-content: right;
    margin-top: 5px;
}

/* chats filter section */
.chats-filter-dropdown-section{
    margin-top: 10px;
    padding: 5px 10px;
    height: 40px;
}

.selected-chat-filter{
    display: flex;
    align-items: center;
    padding: 0px 0px;
    font-size: 18px;
    font-weight: bold;
}

.chats-filter-dropdown-trigger{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    cursor: pointer;
    padding: 0px 0px;
    margin-right: 0px !important;
}

.chats-filter-dropdown-menu {
    border-radius: 5px;
    position: absolute;
    right: 0px;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: #fff;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    list-style: none;
  }

  .chats-filter-dropdown-menu li{
    padding: 10px 10px;
    cursor: pointer;
    border-bottom: 1px solid rgb(222, 222, 222);
    font-size: 14px;
    font-weight: 600;
  }

  .chats-filter-dropdown-menu li:hover{
     background-color: #F2F2F2;
  }

  .chats-filter-dropdown-menu-container {
    border-radius: 5px;
    z-index: 1;
    position: absolute !important;
    display: none;
    width: 200px;
    height: 300px;
    padding: 30px !important;
    margin-top: 5px;
  }

  .chats-count-session{
    display: flex;
    align-items: center;
    position: absolute !important;
  }

  .total-chats-count{
    font-size: 14px;
    font-weight: bold;
    margin-right: 3px;
  }

  .total-unread-chats-count{
    font-size: 14px;
    font-weight: bold;
    margin-left: 3px;
    color: rgb(5, 111, 3);
  }

  .chats-actions-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  #new-chat-btn{
    font-size: 30px;
    color:#08a428;
    cursor: pointer;
  }


  .contacts-section{
    height: 85% !important;
    overflow-y: scroll;
  }
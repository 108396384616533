.table-page {
    padding: 15px 20px;
    margin: 12px;
    width: 100%;
    border-radius: 5px;
}

.table-section {
    padding: 0px 0px;
    box-shadow: 0 0 1.5rem 0 rgb(106 102 100 /30%);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
}

.table-actions-sections{
    padding: 10px 0px;
    display: flex;
    flex-direction: column !important;
}
 .table-page-title{
    font-size: 18px;
    font-weight: 600;
}

.table-toolbar{
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
}
    

.create-btn {
    background-color:#00B4DB;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 9px 15px !important;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 12px;
}


.search-table-input {
    padding: 5px 10px;
    border-radius: 6px;
    border: 1px solid #c0c0c081;
    font-size: 14px !important;
    background-color: #ffffff;
    width: 300px;
    /* width: 100% !important; */
}

.search-table-input:focus {
    outline: none;
}




.step{
    background-color: #eef4fb !important;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 20px;
  }

  .step1-true, .step2-true{
    font-weight: 600;
    color: #2385f4;
    cursor: default;
  }




/*------- table status values styling------- */
#status-RECEIVED{
    background-color: #22c55e3a;
    color: #22c55e;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 80px;
    text-align: center;
    text-transform: capitalize !important;
}


#status-SENT {
    background-color: #eab2083f;
    color: #eab308;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 80px;
    text-align: center;
    text-transform: capitalize !important;
}

#direction-INBOUND {
    background-color: #4463ef3f;
    color: #4455ef;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 100px;
    text-align: center;
    text-transform: capitalize !important;
}

#direction-OUTBOUND {
    background-color: #ef44443f;
    color: #ef4444;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 100px;
    text-align: center;
    text-transform: uppercase  !important;
}

#contact-name{
    cursor: pointer;
    /* background-color: #bfbfbf3f; */
    color: #3c77d8;
    font-weight: bold;
    padding: 2px 0px;
    /* border-radius: 10px; */
    font-size: 14px;
    width: 100px;
    /* text-align: center; */
}



/* BILLING TOOLBAR SECTION */


.filter-billing-section{
    margin: 15px 0px;
}
.filter-label{
    font-size: 14px;
    font-weight: 600;
}
.filter-select-field {
    border: 1px solid rgb(194, 194, 194);
    padding: 4px 7px;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 5px;
    width: 150px;
}

.filter-select-field:focus {
    border: 1px solid rgb(194, 194, 194);
    outline: none;
}

.table-actions {
    display: inline-block;
}

.users-table-actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

#user-edit-action-icon {
    background-color: #cccccc5f;
    color: rgb(72, 72, 72);
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid rgb(172, 172, 172);
    border-radius: 5px;
    padding: 5px;
    margin: 0px 2px;
}


#suspend-user-action-icon{
    background-color: #cccccc5f;
    color: rgb(72, 72, 72);
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    border: 1px solid rgb(172, 172, 172);
    border-radius: 5px;
    padding: 3px 5px;
    margin: 0px 2px;
}

#archive-action-icon {
    background-color: #cccccc5f;
    color: rgb(72, 72, 72);
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid rgb(172, 172, 172);
    border-radius: 5px;
    padding: 5px;
    margin: 0px 2px;
}

#delete-action-icon {
    background-color: #cccccc5f;
    color: rgb(72, 72, 72);
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid rgb(172, 172, 172);
    border-radius: 5px;
    padding: 5px;
    margin: 0px 2px;
}

#view-action-icon {
    background-color: #cccccc5f;
    color: rgb(72, 72, 72);
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid rgb(172, 172, 172);
    border-radius: 5px;
    padding: 5px;
    margin: 0px 2px;
}


/* //////SLECT FIELD STYLING/////////////// */
.select-group {
    display: flex;
    flex-direction: column;
}

.form-label {
    font-size: 13px;
    font-weight: 400;
    color: rgb(38, 39, 39);
}

.select-control {
    padding: 7px 5px;
    border-radius: 3px;
    border: 1px rgb(175, 166, 166) solid;
    font-size: 13px;
    background-color: rgb(240, 240, 240);
}

.select-control:focus {
    outline: none;
    border-color: #318fbb;
    box-shadow: 0 0 3px #3184bb;
    background-color: rgb(255, 255, 255);
}

option:hover {
    background-color: #1882A8 inset;
}

select {
    -webkit-appearance: none;
    /* for webkit (safari, chrome) compatibility */
    -moz-appearance: none;
    /* for firefox compatibility */
    appearance: none;
}


#editable-select {
    height: 33px;
    border-radius: 3px;
    border: 1px rgb(175, 166, 166) solid;
    font-size: 0px !important;
    font-weight: 100;
    background-color: rgb(240, 240, 240);
}

#editable-select {
    outline: none;
    border-color: #318fbb;
    box-shadow: 0 0 3px #3184bb;
    background-color: rgb(255, 255, 255);
}

@keyframes show-modal {
    0% {
        transform: scale(0);
    }

    60% {
        transform: scale(1.1);
    }

    80% {
        transform: scale(.95);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes hide-modal {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0);
    }
}


#status-ONLINE {
    background-color: #22c55e3a;
    color: #22c55e;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 100px;
    text-align: center;
    text-transform: capitalize !important;
}

#status-OFFLINE {
    background-color: #ef44443f;
    color: #ef4444;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 13px;
    width: 100px;
    text-align: center;
    text-transform: lowercase  !important;
}




/* ---- material ui table styles---- */

/* .MuiDataGrid-columnHeaders .css-z8fhq1-MuiDataGrid-columnHeaders{
    min-height: 30px !important;
    max-height: 30px !important;
    line-height: 30px !important;
}



.css-1rhx0ac-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent {
    font-size: bolder !important;
    font-size: 14px !important;
}
.css-1jbbcbn-MuiDataGrid-columnHeaderTitle{
    font-size: bolder !important;
    font-size: 14px !important;
}

.MuiDataGrid-columnSeparator {
    display:none !important;
}

.MuiDataGrid-root {
    border: none !important;
} */








/* DatePickerStyles.css Example custom styling  */
 .date-range-container{
    display: flex;
   justify-content: right;
}

.date-range-section{
    display: flex;
    align-items: center;
}

#date-range-trigger{
    cursor: pointer;
}

#date-range-header{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0 !important;
    color: rgba(0, 0, 0, 0.917);
    margin-right: 5px;
}


.featured-custom-datepicker {
    font-family: 'Nunito', sans-serif;
  }
  
  .featured-custom-datepicker .react-datepicker__header {
    background-color: #00B0E3;
    color: #fff !important;
  }
  
  .featured-custom-datepicker .react-datepicker__day {
    color: #333;
  }
  
  .featured-custom-datepicker .react-datepicker__day--selected {
    background-color: #00B0E3;
    color: #fff;
  }

  .featured-custom-datepicker input {
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

.password-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(245, 244, 244);
}

.password-page-section {
    height: auto;
    width: 40%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    box-shadow: var(--box-shadow);
    background-image: linear-gradient(to right top, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
}


.password-left-content {
    text-align: center;
    width: 100%;
}

.password-welcome-header {
    font-size: 40px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}

.password-welcome-p {
    font-size: 18px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}


.password-form-section {
    background-color: #fff;
    height: 100%;
    width: 40%;
    border-radius: 10px;
    padding: 0px 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.password-form {
    margin: auto auto !important;
    padding: 20px 10px;
    width: 100%;
}

.password-form-header {
    font-size: 20px;
    padding: 10px 0px;
    color: #424242;
    font-weight: bold;
}

.password-form-hint {
    font-size: 18px;
    padding: 10px 0px;
    color: #424242;
    font-weight: 300;
}

#password-content-list {
    list-style: circle;
    padding-left: 20px;
    color: #424242;
}

#content-list-item {
    padding: 4px 0px;
    font-size: 16px;
}


#show-password {
    display: flex;
}

#show-password p {
    padding: 10px 4px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    color: #424242;
}

.password-lipachat-logo {
    width: 150px;
    display: block;
    margin: auto;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
}

.password-label {
    color: rgb(59, 59, 59);
    font-size: 14px;
    margin-bottom: 2px;
    font-weight: bold;
}

.password-input {
    padding: 7px 10px;
    border-radius: 5px;
    border: 1px solid rgb(211, 211, 211);
    font-size: 16px;
}

.password-input:focus {
    outline: none;
    border: 1px solid rgb(0, 145, 255);
}

.password-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    background-color: rgb(0, 145, 255);
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
}

.password-disable {
    width: 100%;
    padding: 10px 10px;
    border-radius: 5px;
    border: none;
    background-color: rgb(0, 145, 255);
    margin-top: 10px;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
}

#form-error {
    color: red !important;
    font-size: 12px;
}

@media screen and (max-width: 990px) {
    .password-page-section {
        flex-direction: column;
        width: 100%;
    }

    .password-form-section{
        width: 100%;
        padding-bottom: 30px;
    }

    .password-left-content {
       height: 250px;
       padding-top: 30px;
    }
}


@media screen and (max-width: 570px) {
    .password-page {
        padding: 0px 10px;
    }
}




/* LOADING ANIMATION */
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    border: 2px solid rgb(255, 255, 255) !important;
    border-color: rgb(255, 255, 255) transparent rgb(254, 255, 255) transparent !important;
    animation: lds-dual-ring 0.9s linear infinite !important;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.inbox-right-sidebar{
    width: 22%;
    min-height: 100% !important;
    padding: 20px 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.username-status-section{
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid rgb(197, 197, 197);
}

.username-status-section i{
    font-size: 25px;
    background-color: rgba(120, 244, 120, 0.335);
    border-radius: 100%;
    padding: 10px;
    margin-right: 15px;
    color: #00b120;
  }

.username-status-section p{
    font-size: 14px;
    font-weight: bold;
}

.username-status-section span{
    font-size: 14px;
    font-weight: 500;
}

.basic-info-section{
    padding: 20px 0px;
    border-bottom: 1px solid rgb(197, 197, 197);
 }

.basic-info-section span{
   font-size: 14px;
   font-weight: bold;
}

.basic-info-section p{
    font-size: 14px;
    font-weight: 600;
    color: rgb(126, 126, 126);
 }
.analytics-grid {
    padding: 25px 40px;
    width: 100%;
  }

  .analyticst-date-range-section p{
    font-weight: bold;
    font-size: 16px;
  }

  .analyticst-date-range-filter{
    border: 1px solid rgb(231, 231, 231);
    border-radius: 5px;
    background: rgb(255, 255, 255);
    display: flex;
    margin-top: 8px;
    width: max-content;
    padding: 10px;

  }

  .data-picker-from{
    margin-right: 45px;
  }

  .conversations-overview{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100% !important;
    margin-top: 25px;
  } 
  
 .analytics-card {
    padding: 15px 20px;
    border-radius: 7px;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    max-height: 100px;
    min-width: 300px;
    height: 100px;
    align-items: center;
    background-color: #ffff;
    text-decoration: none !important;
    font-size: 14px;
    font-weight: 500;
    background-color: #fff;
    text-align: center;
    margin: 10px 10px;
  } 


.analytic-title{
    font-size: 16px;
    font-weight: bold;
    
 }
 .analytic-value{
    font-size: 25px;
    font-weight: 600;
    color: rgba(29, 28, 28, 0.621);
 }


  /* Style the input field */
.MuiTextField-root .MuiInputBase-input {
  padding: 10px !important;
  font-size: 16px !important;
}

/* Style the button for choosing the date */
.MuiTextField-root .MuiButtonBase-root {
  /* Add your styles here */
}

/* Style the calendar icon */
.MuiTextField-root .MuiSvgIcon-root {
  
}

/* Style the outline of the input field */
.MuiTextField-root .MuiOutlinedInput-notchedOutline {
  border-radius: 10px;
}
  
  
  
.table-page {
    padding: 5px 20px;
    margin: 2px;
    width: 100%;
    border-radius: 5px;
}

.table-section {
    padding: 0px 0px;
    box-shadow: 0 0 1.5rem 0 rgb(106 102 100 /30%);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    margin: 0px 5px;
}
.table-loading{
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    padding: 12% 10px;

}

.table-actions-sections{
    padding: 10px 0px;
    display: flex;
    flex-direction: column !important;
}
 .table-page-title{
    font-size: 18px;
    font-weight: 600;
}

.table-toolbar{
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
}
    

.create-btn {
    background-color:#00B4DB;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 9px 15px !important;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 12px;
}

.import-btn {
    background-color:#00db6a;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 9px 15px !important;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 12px;
}
.export-btn {
    background-color:#db8e00;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 9px 15px !important;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 12px;
}


.search-table-input {
    padding: 5px 10px;
    border-radius: 6px;
    border: 1px solid #c0c0c081;
    font-size: 14px !important;
    background-color: #ffffff;
    
    /* width: 100% !important; */
}

.search-table-input:focus {
    outline: none;
}







.step{
    background-color: #eef4fb !important;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 20px;
  }

  .step1-true, .step2-true{
    font-weight: 600;
    color: #2385f4;
    cursor: default;
  }




/*------- table status values styling------- */
#status-RECEIVED{
    background-color: #22c55e3a;
    color: #22c55e;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 80px;
    text-align: center;
    text-transform: capitalize !important;
}


#status-SENT {
    background-color: #eab2083f;
    color: #eab308;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 80px;
    text-align: center;
    text-transform: capitalize !important;
}

#direction-INBOUND {
    background-color: #4463ef3f;
    color: #4455ef;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 100px;
    text-align: center;
    text-transform: capitalize !important;
}

#direction-OUTBOUND {
    background-color: #ef44443f;
    color: #ef4444;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    width: 100px;
    text-align: center;
    text-transform: uppercase  !important;
}

#contact-name{
    cursor: pointer;
    /* background-color: #bfbfbf3f; */
    color: #3c77d8;
    font-weight: bold;
    padding: 2px 0px;
    /* border-radius: 10px; */
    font-size: 14px;
    width: 100px;
    /* text-align: center; */
}



/* BILLING TOOLBAR SECTION */


.filter-billing-section{
    margin: 15px 0px;
}
.filter-label{
    font-size: 14px;
    font-weight: 600;
}
.filter-select-field {
    border: 1px solid rgb(194, 194, 194);
    padding: 4px 7px;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 5px;
    width: 150px;
}

.filter-select-field:focus {
    border: 1px solid rgb(194, 194, 194);
    outline: none;
}

.table-actions {
    display: inline-block;
}

#edit-action-icon {
    background-color: #cccccc5f;
    color: rgb(72, 72, 72);
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid rgb(172, 172, 172);
    border-radius: 5px;
    padding: 5px;
    margin: 0px 2px;
}

#delete-action-icon {
    background-color: #cccccc5f;
    color: rgb(72, 72, 72);
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid rgb(172, 172, 172);
    border-radius: 5px;
    padding: 5px;
    margin: 0px 2px;
}

#view-action-icon {
    background-color: #cccccc5f;
    color: rgb(72, 72, 72);
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid rgb(172, 172, 172);
    border-radius: 5px;
    padding: 5px;
    margin: 0px 2px;
}



/* contact create modal  */

.create-modal-inactive {
    display: none;
    animation: hide-modal .7s ease-in-out;
}

.create-modal-active {
    display: block;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 7;
}

.view-modal-inactive {
    display: none;
    animation: hide-modal .7s ease-in-out;
}

.view-modal-active {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 15px;
    position: fixed;
    z-index: 7;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.create-modal-content {
    background-color: #ffff;
    margin: 20px auto;
    height: auto;
    width: 90%;
    border-radius: 15px;
    animation: show-modal .6s ease-in-out;
    z-index: 7;
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(20px, 1fr));
    grid-gap: 10px;
}

.view-modal-content {
    margin-top: 20px !important;
    background-color: #ffff;
    margin: auto auto;
    width: 50%;
    height: auto;
    padding: 10px 20px;
    border-radius: 10px;
    animation: show-modal .7s ease-in-out;
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(20px, 1fr));
    grid-gap: 10px;
    z-index: 7;
}

.create-modal-inputs,
.view-modal-inputs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 15px;
}

.modal-title {
    font-size: 18px;
    padding: 2px 0px;
    margin-bottom: 10px;
    opacity: 0.7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
}


#close-button {
    background-color: rgb(238, 25, 25);
    border: none;
    color: white;
    font-weight: bolder !important;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    padding: 2px;
    font-weight: bold;
}

#back-button {
    /* background-color: rgb(238, 25, 53);
    border: none;
    color: white;
    font-weight: bolder !important;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 12px;
    font-weight: bold; */

    padding: 10px 7px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background-color: rgb(238, 206, 25);
    border: none;
    border-radius: 8px;
    margin-top: 20px;
    margin-right: 5px;
    width: 120px;
    cursor: pointer;
    text-align: center;
}

/* ---- material ui table styles---- */

.MuiDataGrid-columnHeaders .css-z8fhq1-MuiDataGrid-columnHeaders{
    min-height: 30px !important;
    max-height: 30px !important;
    line-height: 30px !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-weight: bolder !important;
    font-size: 15px !important;
}

.MuiDataGrid-columnHeader {
    outline: none !important;
    cursor: pointer !important;
    background-color: rgba(180, 213, 224, 0.23);
}

.css-tlpi44-MuiDataGrid-root .MuiDataGrid-cellContent {
    font-weight: 600 !important;
    outline: none !important;
}

.css-tlpi44-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    font-weight: 600 !important;
    outline: none !important;
}

.css-1rhx0ac-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
   font-weight: 600 !important;
   outline: none !important;
}


.MuiDataGrid-columnSeparator {
    display:none !important;
}

.MuiDataGrid-root {
    border: none !important;
}



/* DatePickerStyles.css Example custom styling  */
 .date-range-container{
    display: flex;
   justify-content: right;
}

.date-range-section{
    display: flex;
    align-items: center;
}

#date-range-trigger{
    cursor: pointer;
}

#date-range-header{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0 !important;
    color: rgba(0, 0, 0, 0.917);
    margin-right: 5px;
}


.featured-custom-datepicker {
    font-family: 'Nunito', sans-serif;
  }
  
  .featured-custom-datepicker .react-datepicker__header {
    background-color: #00B0E3;
    color: #fff !important;
  }
  
  .featured-custom-datepicker .react-datepicker__day {
    color: #333;
  }
  
  .featured-custom-datepicker .react-datepicker__day--selected {
    background-color: #00B0E3;
    color: #fff;
  }

  .featured-custom-datepicker input {
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }


  .contacts-table-actions{
    display: flex;
    justify-content: space-between;
  }

.login-page {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    background-image: url('../../../public/images/background.png');
    background-color: #ffff;
    background-repeat: no-repeat;
    background-size: 100%;
}

.left-side {
    width: 40%;
    display: flex;
    align-items: center;
    height: 100vh;
}


.left-content {
    width: 100%;
    margin: auto;
    padding: 0px 20px;
}

.left-content-header {
    font-size: 25px;
    font-weight: bold;
    color: #222222 !important;
}

#content-list {
    list-style: circle;
    padding: 10px 20px;
    color: #222222 !important;
}

#content-item {
    font-size: 18px;
    font-weight: 600;
    padding: 5px 0px;
    color: #222222 !important;
}

#website-link {
    background-color: rgb(0, 145, 255);
    text-decoration: none;
    color: white;
    padding: 5px 10px;
    margin-left: 0px;
    margin-top: 10px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
}

.login-lipachat-logo {
    width: 120px;
    display: block;
}

.welcome-header {
    font-size: 25px;
    padding: 10px 0px;
    font-weight: bold;
    color: #242525;
}

.login-title2{
    color: #5a6370;
    font-size: 14px;
}

.login-form-section {
    background-color: #fff;
    min-height: auto;
    width: 35%;
    display: flex;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 7px;
    border-radius: 15px;
}

.login-form {
    margin: auto auto !important;
    padding: 20px 20px;
    width: 100%;
}


.input-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
}

.login-label {
    color: rgb(59, 59, 59);
    font-size: 14px;
    margin-bottom: 2px;
    font-weight: 600;
}

.login-input {
    padding: 7px 10px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #c0c0c081;
    font-size: 14px !important;
    background-color: #F2F2F2;
}

.login-input:focus {
    outline: none;
    border: 1px solid rgb(0, 132, 255);
    background-color: #fff;
}

.login-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    background-color: rgb(0, 145, 255);
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
}

.login-button:hover {
    opacity: 0.7;
    transition: opacity .3s ease-in-out;
}

.login-disable {
    width: 100%;
    padding: 10px 10px;
    border-radius: 5px;
    border: none;
    background-color: rgb(0, 145, 255);
    margin-top: 10px;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
}

.login-actions {
    margin-top: 15px;
}

.forgot-link {
    text-decoration: none;
    color: #0083B0;
    font-size: 14px;
    background-color: transparent !important;
}


.signup-section {
    text-align: left !important;
}

.signup-link {
    font-weight: 600;
    text-decoration: none;
    color: #0083B0;
}


#form-error {
    color: red;
    font-size: 12px;
}

@media screen and (max-width: 990px) {
    .login-page {
        flex-direction: column;
    }

    .left-side {
        width: 100%;
        height: 100vh;
    }

    .login-form-section {
        width: 100%;
    }

    .login-form {
        width: 50%;
    }

}

@media screen and (max-width: 780px) {
    .login-form {
        width: 80%;
    }

    .left-content {
        width: 80%;
    }
}

@media screen and (max-width: 570px) {
    .left-content {
        width: 100%;
    }

    .login-form {
        width: 95%;
    }
}









/* LOADING ANIMATION */
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    border: 2px solid rgb(255, 255, 255) !important;
    border-color: rgb(255, 255, 255) transparent rgb(254, 255, 255) transparent !important;
    animation: lds-dual-ring 0.9s linear infinite !important;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.countdown-timer {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background-color: #0ca22a31;
    width: max-content;
    color: #10c61f;
    padding: 5px 2px;
    border-radius: 5px;
  }
  
  .countdown-timer span {
    margin: 0 5px;
  }

  .session-expired{
    font-size: 14px;
    font-weight: bold;
    padding: 5px 2px;
    width: 100%;
    color: red;
    background-color: #d418181d;
    border-radius: 4px;
    text-align: center;
   
  }
  
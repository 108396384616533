.inbox-chat-section{
    min-width: 55%;
    flex: 1;
    background-color: aliceblue;
}

.actions-chat-section{
  background-color: #ffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.active-agent-actions{
  display: flex;
  align-items: center;
  padding: 10px 0px;
  width: 40%;
}

.session-timer-div{
  width: 50%;
}

.session-timer-div p{
  width: 100%;
}

.active-agent-status{
  /* display: flex; */
  align-items: center;
  padding: 10px 0px;
  width: 90%;
  margin-left: 20px;
}
.active-agent-status i{
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(120, 244, 120, 0.335);
  border-radius: 100%;
  padding: 10px;
  color: #00b120;
}

.agent-status-info{
  font-size: 14px;
  font-weight: 600;
  width: 100%;
}

.agent-actions-select-field{
  padding: 7px 10px;
  border-radius: 6px;
  border: 1px solid rgb(198, 198, 198);
  font-size: 14px !important;
  background-color: #F2F2F2;
  width: 100% !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.agent-actions-select-field:focus{
  outline: none;
}

.agent-ticket-status-section{
  width: 21%;
}


.messages-section {
  padding: 0px 20px;
  height: calc(100% - 100px);
  overflow-y: auto;
  scroll-behavior: smooth;
}

.messages-section::-webkit-scrollbar {
  width: 8px;
}

.messages-section::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.messages-section::-webkit-scrollbar-thumb:hover {
  background: #555;
}



.outgoing-message-sec{
  display: flex;
  justify-content: right;
  
}
.outgoing-message{
  background-color: rgba(8, 224, 8, 0.312);
  padding: 7px 10px;
  border-radius: 10px;
  min-width: 40%;
  max-width: 60%;
  margin: 2px 0px;
  margin-bottom: 5px;
  float: right;
}

.incoming-message-sec{
  display: flex;
  justify-content: left;
}
.incoming-message{
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
  padding: 7px 10px;
  border-radius: 10px;
  border: none !important;
  min-width: 40%;
  max-width: 60%;
  margin: 2px 0px;
  background-color: #ffffff;
}

.message-content{
    font-size: 14px;
    font-weight: 600;
    max-height: 6em; 
    color: #575757; 
    word-wrap: break-word;
}

.image-content{
  /* font-size: 14px;
  font-weight: 600;
  max-height: 6em; 
  color: #575757;  */
  width: 100% !important;
}


.message-datetime{
    font-size: 12px;
    font-weight: bold;
    color: #575757;
    display: flex;
    justify-content: right;
    margin-top: 5px; 
}


/* chatbox input section */

.chatbox-input {
  width: 100%;
  height: 11%;
  background: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  position:sticky;
}

.chatbox-input i {
  cursor: pointer;
  font-size: 1.8em;
  color: #515855;
}

#messageInput{
  font-size: 15px;
  font-weight: 600;
}

.chatbox-input i:nth-child(1) {
   margin: 15px;
}

.chatbox-input i:last-child {
  margin-right: 25px;
}

 .chatbox-input input {
    position: relative;
    bottom: 0;
    width: 90%;
    margin: 0 20px;
    padding: 10px 20px;
    border-radius:10px;
    font-size: 1em;
    border:none;
    outline:none;
 }

 /* send message button */
 .send-message-button {
  width:7rem;
  background-color:#00B4DB;
  border: none;
  color: #ffff;
  cursor:pointer;
  line-height:2.5;
  font-size:16px;
  border-radius: 15px;
  outline:none;
  transition:transform .125s;

  &:active {
      transform:scale(.9,.9);
  }

  &:before,
  &:after {
      position:absolute;
      opacity:0;
      border-radius:50%;
      background-color:#fff;
      top:50%;
      left:50%;
      margin-top:-1.125rem;
      margin-left:-1.125rem;
      width:2.25rem;
      height:2.25rem;
      content:'';
      z-index:1;
  }
}

.select-temp-btn{
  padding: 7px 15px;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  background-color: #00B4DB;
  cursor: pointer;
}
.send-template-section{
  position: relative;
}
.list-tamplates-sec{
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 380px;
  position: absolute;
  bottom: 140%; /* changed from top: 25% */
  right: 2%;
  height: 40vh !important;
  overflow-y: scroll;
  border-radius: 8px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 5px;
}

.list-tamplates-sec .filter-template{
  border: 1px solid rgba(90, 90, 90, 0.504);
  padding: 8px 10px;
  width: 90%;
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
}

.grid-template-item{
  padding: 0px 5px !important;
  border-radius: 6px;
}

.grid-template-item:hover{
  background-color: #00b3db40;
}


.body-variable-inputs{
  display: flex;
}

@media screen and (max-width: 980px) {
  .active-agent-actions {
      width: 60%;
  }

  .agent-ticket-status-section{
      width: 38%;
  }
}


@media screen and (max-width: 780px) {
  .active-agent-actions {
      width: 100%;
  }

  .agent-ticket-status-section{
      width: 78%;
  }
  .actions-chat-section{
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   
  }
}
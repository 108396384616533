.whatsapp-template-preview {
  opacity: 1;
  background-color: rgb(210, 210, 210);
  background-image: url('../../../public/images/whatsapp-bg.png');
  background-size: 100%;
  padding: 15px 10px;
  margin: 20px 0px;
  width: 280px;
  border-radius: 15px;
}

.official-account-info {
  font-size: 14px;
  font-weight: bold;
  color: #484848;
  margin-bottom: 10px;
}

.message-box {
  background-color: #ffffff;
  padding: 10px 12px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.image-preview img {
  display:block;
  width: 100%;
  margin: auto auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.header-details p{
 font-size: 14px;
 font-weight: bold;
 margin-bottom: 1px;
}


.message-text {
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.footer {
  font-weight: 600;
  font-size: 11px;
  color: #8a8a8a;
}

.template-timestamp {
  font-size: 11px;
  font-weight: bold;
  color: #757575;
  text-align: right;
  
}

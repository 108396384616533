
.user-profile-page{
    padding: 15px 100px;
    width: 100%;
    /* background-color: red; */
}

.main-profile-section{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 10px;
    justify-content: space-between;
    margin-top: 20px;
    border-radius: 5px;
}

.user-info-section{
    border-radius: 5px;
    padding: 10px 10%;
    box-shadow: var(--box-shadow);
    background-color: #ffff;
}

.user-info-section-div{
    border-radius: 5px;
    border: 1px solid rgba(201, 201, 201, 0.835);
    padding: 10px;
}

.profile-title{
    font-weight: bold;
    font-size: 20px;
    color: #2f3033;
}

#user-profile-info {
    font-weight: 400;
    padding: 4px 0px;
    font-size: 14px;
    color: #5a6370;
}

#user-profile-info #info-header{
    font-weight: 600;
    padding: 4px 0px;
    font-size: 14px !important;
    color: black;
}


#error {
    font-size: 12px;
    color: rgb(221, 34, 34);
    margin-top: 5px;
}

.profile-edit-btn{
    color: #22c55e;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.profile-edit-btn:hover{
    border-bottom: #22c55e 1px solid;
}


#profile-btn-icon{
    margin-right: 3px;
    font-size: 20px;
}

.info-fields-section{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 15px;
}
.profile-btns-header-section{
    display: flex;
}

.profile-form-btn{
    color: #ffff;
    font-weight: 600;
    padding: 7px 12px;
    border-radius: 10px;
    border: none;
    background-color:#00B4DB;
    cursor: pointer;
    margin-right: 20px;
}
.main-container{
    height: 100vh;
    padding: 20px 8%;
 }
 
 .container-title{
     font-size: 28px;
     text-align: center;
     margin: 35px 0px;
     color: #5ACCCC;
 }
 
 .container-title span{
     color: #faad00 !important;
 }
 
 .search-book-section {
     position: relative;
     display: flex;
     flex-direction: column;
     justify-content: center;
     margin-bottom: 5px;
 }
 
 .search-book-input {
     padding: 6px 5px;
     border-radius: 6px;
     border: 1px solid rgb(206, 206, 206);
     background-color: rgba(219, 219, 219, 0.322);
     font-size: 14px !important;
     min-width: 50%;
     position: sticky;
     top: 0; 
     z-index: 1000;
 }
 
 .search-book-input:focus {
     outline: none;
     border-color: #28b8b8;
 }
 
 .search-list-section{
     background-color: #ffffff;
     box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
     width: 100%;
     position: absolute;
     top: 100%; /* changed from top: 25% */
     height: 30vh !important;
     overflow-y: scroll;
     border-radius: 8px;
     z-index: 1000;
     box-sizing: border-box;
 }
 
 .start-convo-temp-btn {
     padding: 8px 10px;
     font-size: 12px;
     font-weight: bold;
     color: white;
     background-color:#28b8b8;
     border: none;
     border-radius: 5px;
     cursor: pointer;
     width: 100px;
 }
 .book-item{
    padding: 0px 5px !important;
    cursor: pointer;
}

 .book-item:hover{
     background-color: #cffafa;
 }
 
 #not-found-info{
     padding: 2px 10px;
     font-weight: bold;
     font-size: 12px;
 }
 
 #book-cover{
  width: 100%;
  height: 70%;
 }
 
 #book-title{
    font-size: 14px;
    font-weight: bold;
 }
 
 #book-author{
     font-size: 12px;
     font-weight: 600;
     color: #a3a3a3;
  }
 
  .book-author-level{
     display: flex;
     justify-content: space-between;
  }
 
  .load-more-section{
     display: flex;
     justify-content: center;
     align-items: center;
     margin-top: 20px;
  }
 
  .load-more-btn{
     font-size: 14px;
     font-weight: bold;
     color:#28b8b8;
     background: transparent;
     border: 1px solid #28b8b8;
     padding: 8px 10px;
     border-radius: 10px;
     cursor: pointer;
  }
 
  .load-more-btn:hover{
     color:#ffffff;
     background: #28b8b8;
  }
 
 
 @media screen and (max-width: 720px) {
     .search-book-input {
        width: 100%;
     }
     .search-list-section{
         width: 100%;
     }
 }